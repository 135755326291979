import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Ecommerce from "../components/blog-details-page/Ecommerce"
import MostPopular from "../components/blog-details-page/MostPopular"
import * as styles from "../components/saas-files/blogPost.module.scss"
import MainLayout from "../layouts/MainLayout"
export const Head = () => {
  return (
    <>
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
const BlogPostTemplate = ({ location }) => {
  const [blogData, setBlogData] = useState(null)
  const [writerData, setWriterData] = useState(null)
  var ID = ""

  useEffect(() => {
    if (location.search) {
      let params = new URL(location.href)?.searchParams
      ID = params?.get("id")
    } else {
      navigate("/")
    }
  }, [location])

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetch(
          // `https://cms.invozone.com/api/blog-posts/51?populate=*`,
          `https://cms.invozone.com/api/blog-posts/${ID}?populate[0]=seo.schemas&populate[1]=categories&populate[2]=featuredImage&populate[3]=writer.image&populate[4]=writer.seo&populate[5]=faqs.cards&populate[6]=relatedBlogs.blogs&populate[7]=contributed_by.image`,
          {
            method: "GET",
          }
        ).then(res => res.json())

        if (result) {
          const schemaChange = {
            featuredImage: {
              localFile: {
                publicURL:
                  result?.data?.attributes?.featuredImage?.data?.attributes
                    ?.url,
              },
            },
            post: result?.data?.attributes?.rawPost,
            slug: result?.data?.attributes?.slug,
            title: result?.data?.attributes?.title,
            isWP: false,
            publishedOn: result?.data?.attributes?.publishedOn,
            choose_a_date: result?.data?.attributes?.choose_a_date,
            seo: {
              metaTitle: result?.data?.attributes?.seo?.metaTitle,
              metaDescription: result?.data?.attributes?.seo?.metaDescription,
              schemas: result?.data?.attributes?.seo?.schemas,
            },
            updated_at: result?.data?.attributes?.updatedAt,
            created_at: result?.data?.attributes?.createdAt,
            readingTime: result?.data?.attributes?.readingTime,
            summary: result?.data?.attributes?.rawSummary,
            categories: [
              {
                name: result?.data?.attributes?.categories?.data[0]?.attributes
                  ?.name,
                slug: result?.data?.attributes?.categories?.data[0]?.attributes
                  ?.slug,
              },
            ],
            writer: {
              name: result?.data?.attributes?.writer?.data?.attributes?.name,
              slug: result?.data?.attributes?.writer?.data?.attributes?.slug,
            },
            contributed_by: {
              name: result?.data?.attributes?.contributed_by?.data?.attributes
                ?.name,
              slug: result?.data?.attributes?.contributed_by?.data?.attributes
                ?.slug,
              isCreatePage:
                result?.data?.attributes?.contributed_by?.data?.attributes
                  ?.isCreatePage,
              status:
                result?.data?.attributes?.contributed_by?.data?.attributes
                  ?.status,
              image:
                result?.data?.attributes?.contributed_by?.data?.attributes
                  ?.image?.data?.attributes,
            },
            faqs: result?.data?.attributes?.faqs && {
              title: result?.data?.attributes?.faqs?.title,
              cards: result?.data?.attributes?.faqs?.cards?.map(v => {
                return {
                  title: v?.title,
                  description: {
                    data: v,
                  },
                }
              }),
            },
            relatedBlogs: result?.data?.attributes?.relatedBlogs && {
              title: result?.data?.attributes?.relatedBlogs?.title,
              blogs: result?.data?.attributes?.relatedBlogs?.blogs?.data?.map(
                v => {
                  return {
                    title: v?.attributes?.title,
                    slug: v?.attributes?.slug,
                  }
                }
              ),
            },
          }

          setWriterData({
            name: result?.data?.attributes?.writer?.data?.attributes?.name,
            slug: result?.data?.attributes?.writer?.data?.attributes?.slug,
            image:
              result?.data?.attributes?.writer?.data?.attributes?.image?.data
                ?.attributes,
            seo: result?.data?.attributes?.writer?.data?.attributes?.seo,
          })

          setBlogData(schemaChange)
        }
      } catch (e) {
        console.error("ERROR: ", e)
      }
    })()
  }, [ID])

  // console.log("BloG:", blogData)

  let headSchema = []
  const bodySchema = blogData?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return !blogData ? (
    <div className="d-flex justify-content-center vh-100 align-items-center">
      <h2>Loading . . .</h2>
    </div>
  ) : (
    <MainLayout schemas={bodySchema}>
      <div className={styles.parrent}>
        <Ecommerce
          modifiedHead={blogData?.updated_at?.split("T")[0]}
          slug={blogData?.slug}
          catagory={blogData?.categories}
          title={blogData?.title}
          featuredImage={blogData?.featuredImage}
          readingTime={blogData?.readingTime}
          isWP={blogData?.isWP}
          publishDate={blogData?.publishedOn}
          choose_a_date={blogData?.choose_a_date}
          AuthorSlug={blogData?.writer?.slug}
          AuthorName={blogData?.writer?.name}
          AuthorImage={blogData?.writer?.image?.url}
          outline={blogData?.summary}
          fromPage={{ slug: "blog", title: "Blog" }}
        />
        <MostPopular
          postData={blogData}
          allData={[]}
          isWP={blogData?.isWP}
          author={writerData}
        />
      </div>
    </MainLayout>
  )
}

export default BlogPostTemplate
